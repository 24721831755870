import { AccessTime, Event, People } from '@mui/icons-material';
import {
  Avatar,
  Button,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { format } from 'date-fns'; // Add this dependency if not already present
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';

import {
  generateIcsApiV1EventInviteExternalIdIcsGet,
  getEventInviteApiV1EventInviteExternalIdGet,
  getGoogleCalendarUrlApiV1EventInviteExternalIdGoogleGet,
} from '../../client';

const SkeletonView = () => (
  <Card
    sx={{
      maxWidth: 400,
      mx: 'auto',
      mt: 4,
      borderRadius: 2,
      boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    }}
  >
    <CardContent>
      <Stack spacing={3}>
        {/* Title Skeleton */}
        <Skeleton variant="text" height={40} width="80%" sx={{ mx: 'auto' }} />
        {/* Time Section Skeleton */}
        <Stack direction="row" alignItems="center" spacing={1}>
          <Skeleton variant="circular" width={24} height={24} />
          <Stack>
            <Skeleton variant="text" width={140} />
            <Skeleton variant="text" width={180} />
          </Stack>
        </Stack>
        {/* Attendees Section Skeleton */}
        <Stack spacing={1}>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Skeleton variant="circular" width={24} height={24} />
            <Skeleton variant="text" width={120} />
          </Stack>
          {[1, 2, 3].map((i) => (
            <Stack key={i} direction="row" spacing={2} alignItems="center">
              <Skeleton variant="circular" width={40} height={40} />
              <Skeleton variant="text" width={150} />
            </Stack>
          ))}
        </Stack>
        {/* Button Skeletons */}
        <Stack spacing={1}>
          <Skeleton variant="rectangular" height={36} width="100%" />
          {/chrome/i.test(navigator.userAgent) && (
            <Skeleton variant="rectangular" height={36} width="100%" />
          )}
        </Stack>
      </Stack>
    </CardContent>
  </Card>
);

const EventInvitePage = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { externalId } = useParams();

  const {
    data: event,
    isLoading,
    error,
  } = useQuery({
    queryKey: ['event', externalId],
    queryFn: () =>
      getEventInviteApiV1EventInviteExternalIdGet({
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        path: { external_id: externalId! },
      }),
    enabled: !!externalId,
  });

  if (!externalId) {
    return <Typography>Event ID not supplied</Typography>;
  }

  if (isLoading) {
    return <SkeletonView />;
  }

  if (error) {
    return <Typography color="error">Error loading event</Typography>;
  }

  if (!event) {
    return <Typography>Event not found</Typography>;
  }

  if (!event.data) {
    return <Typography>Event data not found</Typography>;
  }

  const startTime = new Date(event.data.start_time);
  const endTime = new Date(event.data.end_time);

  const handleAddToCalendar = async () => {
    try {
      const response = await generateIcsApiV1EventInviteExternalIdIcsGet({
        path: { external_id: externalId },
      });

      if (!response.data) {
        enqueueSnackbar('Failed to load event details', { variant: 'error' });
        return;
      }

      // Create blob from response data
      const blob = new Blob([response.data], { type: 'text/calendar' });

      // Create download link
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${event.data.title}.ics`);

      // Trigger download
      document.body.appendChild(link);
      link.click();

      // Cleanup
      link.parentNode?.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (err) {
      enqueueSnackbar('Error downloading calendar file', { variant: 'error' });
      // You might want to show an error message to the user here
    }
  };

  const handleGoogleCalendar = async () => {
    try {
      const response = await getGoogleCalendarUrlApiV1EventInviteExternalIdGoogleGet({
        path: { external_id: externalId },
      });

      if (!response.data?.url) {
        enqueueSnackbar('Failed to generate Google Calendar URL', { variant: 'error' });
        return;
      }

      // Redirect to Google Calendar
      window.open(response.data.url, '_blank');
    } catch (err) {
      enqueueSnackbar('Error opening Google Calendar', { variant: 'error' });
    }
  };

  return (
    <Card
      sx={{
        maxWidth: 400,
        mx: 'auto',
        mt: 4,
        borderRadius: 2,
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
      }}
    >
      <CardContent>
        <Stack spacing={3}>
          {/* Title */}
          <Typography variant="h4" component="h1" align="center" gutterBottom>
            {event.data.title}
          </Typography>

          {/* Time Section */}
          <Stack direction="row" alignItems="center" spacing={1}>
            <AccessTime color="action" />
            <Stack>
              <Typography variant="h6">
                {format(startTime, 'h:mm a')} - {format(endTime, 'h:mm a')}
              </Typography>
              <Typography variant="body1" color="text.secondary">
                {format(startTime, 'EEEE, MMMM d')}
              </Typography>
            </Stack>
          </Stack>

          {/* Attendees Section */}
          <Stack spacing={1}>
            <Stack direction="row" alignItems="center" spacing={1}>
              <People color="action" />
              <Typography variant="h6">People Going ({event.data.attendees.length})</Typography>
            </Stack>
            <List sx={{ pt: 0 }}>
              {event.data.attendees.map((attendee, index) => (
                <ListItem key={index} sx={{ px: 0 }}>
                  <ListItemAvatar>
                    <Avatar src={'/api/placeholder/40/40'} />
                  </ListItemAvatar>
                  <ListItemText primary={attendee} />
                </ListItem>
              ))}
            </List>
          </Stack>

          {/* Calendar Buttons */}
          <Stack spacing={1}>
            <Button
              variant="contained"
              startIcon={<Event />}
              fullWidth
              onClick={handleAddToCalendar}
              sx={{
                bgcolor: '#1a1a1a',
                '&:hover': {
                  bgcolor: '#2c2c2c',
                },
              }}
            >
              Download ICS File
            </Button>

            {/chrome/i.test(navigator.userAgent) && (
              <Button
                variant="contained"
                startIcon={<Event />}
                fullWidth
                onClick={handleGoogleCalendar}
                sx={{
                  bgcolor: '#4285f4', // Google Blue
                  '&:hover': {
                    bgcolor: '#357abd',
                  },
                }}
              >
                Add to Google Calendar
              </Button>
            )}
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default EventInvitePage;
