import { useState } from 'react';

import { TextField, Button, Box, Snackbar, Alert, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';

import { createWaitlistUserApiV1WaitlistUsersPost, CreateWaitlistUserApiV1WaitlistUsersPostError } from '../../client';

const WaitlistSignup = () => {
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
  const [openSuccessSnackbar, setOpenSuccessSnackbar] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const mutation = useMutation({
    mutationFn: () =>
      createWaitlistUserApiV1WaitlistUsersPost({
        body: {
          email,
          first_name: firstName,
          last_name: lastName,
          phone_number: phoneNumber,
          created_at: new Date().toISOString(),
          updated_at: new Date().toISOString(),
        },
        throwOnError: true,
      }),
    onError: (error: CreateWaitlistUserApiV1WaitlistUsersPostError) => {
      if (error.detail && error.detail.length > 0) {
        setErrorMessage(error.detail[0].msg);
      } else {
        setErrorMessage('An error occurred');
      }
      setOpenErrorSnackbar(true);
      setOpenErrorSnackbar(true);
    },
    onSuccess: () => {
      setEmail('');
      setFirstName('');
      setLastName('');
      setPhoneNumber('');
      setOpenSuccessSnackbar(true);
    },
  });

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    mutation.mutate();
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ maxWidth: 400, margin: '0 auto', mt: 4 }}>
      <Typography variant="h4" align="center" gutterBottom>
        Want to offload the burden of scheduling?
      </Typography>
      <TextField
        fullWidth
        required
        label="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        margin="normal"
      />
      <TextField
        fullWidth
        required
        label="First Name"
        value={firstName}
        onChange={(e) => setFirstName(e.target.value)}
        margin="normal"
      />
      <TextField
        fullWidth
        required
        label="Last Name"
        value={lastName}
        onChange={(e) => setLastName(e.target.value)}
        margin="normal"
      />
      <TextField
        fullWidth
        required
        label="Phone Number"
        value={phoneNumber}
        onChange={(e) => setPhoneNumber(e.target.value)}
        margin="normal"
      />
      <Button
        fullWidth
        variant="contained"
        type="submit"
        sx={{ mt: 2 }}
        disabled={mutation.isPending}
      >
        {mutation.isPending ? 'Signing up...' : 'Join Waitlist'}
      </Button>

      <Snackbar open={openErrorSnackbar} autoHideDuration={6000} onClose={() => setOpenErrorSnackbar(false)}>
        <Alert severity="error" onClose={() => setOpenErrorSnackbar(false)}>
          {errorMessage}
        </Alert>
      </Snackbar>

      <Snackbar open={openSuccessSnackbar} autoHideDuration={6000} onClose={() => setOpenSuccessSnackbar(false)}>
        <Alert severity="success" onClose={() => setOpenSuccessSnackbar(false)}>
          Successfully joined the waitlist!
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default WaitlistSignup;