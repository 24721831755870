import React from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import { AppBar, Toolbar, Typography, Button, Container, Box, Paper } from '@mui/material';

import WaitlistSignup from '../../components/WaitlistSignup/WaitlistSignup';

function LoginPage() {
  const { isAuthenticated, loginWithRedirect, logout } = useAuth0();

  const handleLogout = () => {
    logout({ logoutParams: { returnTo: window.location.origin } });
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            BTL: (It&apos;s) Been Too Long
          </Typography>
          {isAuthenticated ? (
            <Button color="inherit" onClick={handleLogout}>
              Log Out
            </Button>
          ) : (
            <Button color="inherit" onClick={() => loginWithRedirect()}>
              Log In
            </Button>
          )}
        </Toolbar>
      </AppBar>
      <Container maxWidth="sm" sx={{ mt: 4 }}>
        <Paper elevation={3} sx={{ p: 3, textAlign: 'center' }}>
          <WaitlistSignup />
        </Paper>
      </Container>
    </Box>
  );
}

export default LoginPage;
